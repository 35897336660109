import { PageSizes, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { useCalc } from '@/views/components/stb_ltf_2024/plugins/calc'
import fontkit from '@pdf-lib/fontkit'
import '@/filter'
import Vue from 'vue'
import urkundenpapier from '../plugins/urkudenpapier'

const saveByteArray = async (fileName, bytes) => {
  const blob = new Blob([bytes], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  window.open(link.href, '_blank').focus()
}

const print = {
  image: async (doc, page, image, x, y, width, height) => {
    const img = image.match(/^data:image\/png/) ? await doc.embedPng(image) : await doc.embedJpg(image)
    page.drawImage(img, { x, y, width, height })
  },
  text: (page, font, size, text, x, y, align = 'l', color = rgb(0, 0, 0)) => {
    if (text === undefined || text === null) return
    text = `${text}`

    if (align === 'c') {
      const textWidth = font.widthOfTextAtSize(text, size)
      x -= textWidth / 2
    } else if (align === 'r') {
      const textWidth = font.widthOfTextAtSize(text, size)
      x -= textWidth
    }
    page.drawText(text, { x, y, size, font, color })
  },
  rectangle: (page, x, y, width, height, line, color) => {
    page.drawRectangle({ x, y, width, height, borderWidth: line, color })
  }
}

export const usePdf = (context) => {
  const calc = useCalc()

  const urkunde = async (e, r, tid, vorlage) => {
    const pdfDoc = await PDFDocument.create()
    pdfDoc.registerFontkit(fontkit)

    const Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const HelveticaItalic = await pdfDoc.embedFont(StandardFonts.HelveticaOblique)
    const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    // const HelveticaBoldItalic = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique)

    const center = PageSizes.A4[0] / 2

    const teams = e.teams
      .map(t => ({
        ...t,
        final: calc.mannschaftergebnis(e, r, calc.teamid(t))
      }))
      .sort((a, b) => a.final > b.final ? -1 : 1)
      .map((t, _, arr) => ({
        ...t,
        place: arr.filter(t2 => t2.final > t.final).length + 1
      }))
      .reduce((acc, t) => {
        if (!tid || calc.teamid(t) === tid) acc.push(t)
        return acc
      }, [])

    for (let i = 0; i < teams.length; i++) {
      if (vorlage) {
        const vorlageDoc = await PDFDocument.load(urkundenpapier)
        const [tmp] = await pdfDoc.copyPages(vorlageDoc, [0])
        pdfDoc.addPage(tmp)
      } else {
        pdfDoc.addPage(PageSizes.A4.slice())
      }
    }

    teams.forEach((t, i) => {
      const page = pdfDoc.getPage(i)

      let y = 530
      print.text(page, HelveticaItalic, 24, e.parent?.name, center, y, 'c')
      y -= 36
      print.text(page, HelveticaItalic, 30, e.name, center, y, 'c')
      y -= 60
      t.athletes.forEach(a => {
        print.text(page, HelveticaBold, 24, Vue.filter('person')(a), center, y, 'c')
        y -= 30
      })
      print.text(page, HelveticaItalic, 16, `(${t.club.name})`, center, y, 'c')
      y -= 40
      print.text(page, Helvetica, 12, 'erreichten mit', center, y, 'c')
      y -= 40
      print.text(page, HelveticaBold, 30, `${Vue.filter('float2_0')(t.final)} Punkten`, center, y, 'c')
      y -= 40
      print.text(page, Helvetica, 12, 'den', center, y, 'c')
      y -= 40
      print.text(page, HelveticaBold, 30, `${t.place}. Platz`, center, y, 'c')
    })

    const pdfBytes = await pdfDoc.save()

    saveByteArray('Urkunde.pdf', pdfBytes)
  }

  return { urkunde }
}
